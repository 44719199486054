<template>
	<div class="animated fadeIn">
		<b-form novalidate @submit.prevent="onSubmit">
			<h4 class="mb-3 multiauction-name">
				Аукцион
				<span
					v-for="playedFlight in data.playedFlights"
					:key="playedFlight.id"
					>{{ playedFlight.routeCode }}</span
				>
			</h4>
			<div class="form-sections">
				<div class="custom-fieldset">
					<b-row>
						<b-col md="12">
							<b-form-group
								class="form-group-el-select"
								label="Разыгрываемый рейс"
							>
								<el-select-clearable
									v-model="form.playedFlightsSelect.value"
									class="form-control"
									name="responsible"
									placeholder="Начните вводить название рейса"
									clearable
									:multiple="true"
									:multiple-limit="0"
									:filterable="true"
									:remote="true"
									reserve-keyword
									:remote-method="customersRoutesForAuctions"
									:loading="loading"
									@change="
										customersRoutesRead(
											form.playedFlightsSelect.value
										)
									"
									@clear="customersRoutesForAuctions('')"
								>
									<el-option
										v-for="item in form.playedFlightsSelect
											.options"
										:key="item.value"
										:label="item.text"
										:value="item.value"
									/>
								</el-select-clearable>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div
					v-for="(playedFlight, index) in data.playedFlights"
					:key="'played-route-' + index"
					class="custom-fieldset"
				>
					<h5 class="custom-fieldset__title">
						<i class="fa fa-map-o fa-lg mr-2" />Маршрут и детали
						рейса
						{{ data.playedFlights[index].routeCode }}
					</h5>
					<b-row
						v-if="data.playedFlights[index]"
						class="route-and-flight-auction-details"
					>
						<b-col xl="6" class="mb-4">
							<div
								v-if="data.playedFlights[index].points"
								class="route-and-flight-auction-details__route-container"
							>
								<h6
									class="
                    route-and-flight-auction-details__route-container-title
                    route-and-flight-auction-details__title
                    custom-fieldset__title
                "
								>
									Маршрут
								</h6>
								<p
									class="route-and-flight-auction-details__route-container-desc"
								>
									В порядке следования рейса
								</p>
								<div
									v-if="
										data.playedFlights[index].points
											.length > 0
									"
									class="route-auction"
								>
									<div
										v-for="point in data.playedFlights[
											index
										].points"
										:key="'point-' + point.rowNumber"
										class="route-auction__item"
									>
										<div class="route-auction__item-header">
											<div
												class="route-auction__item-number"
											>
												Пункт №{{ point.rowNumber }}
											</div>
											<button
												v-if="point.rowNumber === 1"
												class="
                          route-auction__item-type
                          btn btn-square btn-block btn-secondary
                        "
												disabled
											>
												Загрузка
											</button>
											<button
												v-else-if="
													point.rowNumber ===
														data.playedFlights[
															index
														].points.length
												"
												class="
                          route-auction__item-type
                          btn btn-square btn-block btn-secondary
                        "
												disabled
											>
												Разгрузка
											</button>
											<button
												v-else
												class="
                          route-auction__item-type
                          btn btn-square btn-block btn-secondary
                        "
												disabled
											>
												Загрузка/Разгрузка
											</button>
											<div
												class="route-auction__item-distance"
											>
												Расстояние:
												{{
													numberRUFormat(
														Number(
															point.distance
														).toFixed(2)
													)
												}}
												км.
											</div>
										</div>
										<p
											v-if="
												point.department.mainWarehouse
													.address
											"
											class="route-auction__item-address"
										>
											{{
												point.department.mainWarehouse
													.address
											}}
										</p>
										<div
											v-if="point.rowNumber === 1"
											class="route-auction__item-footer"
										>
											<time
												v-if="
													form.playedFlights[index]
														.firstPointLoadingDatetime
												"
												class="route-auction__item-date"
											>
												<i
													class="fa fa-calendar fa-sm mr-3"
												/>{{
													formatDate(
														form.playedFlights[
															index
														]
															.firstPointLoadingDatetime
													)
												}}
											</time>
											<time
												v-if="
													form.playedFlights[index]
														.firstPointLoadingDatetime
												"
												class="route-auction__item-time"
											>
												<i
													class="fa fa-clock-o fa-sm mr-3"
												/>{{
													formatTime(
														form.playedFlights[
															index
														]
															.firstPointLoadingDatetime
													)
												}}
											</time>
										</div>
									</div>
								</div>
								<p
									class="
                    route-and-flight-auction-details__route-container-total
                  "
								>
									Итоговое расстояние:
									<b
										>{{
											numberRUFormat(
												data.playedFlights[index]
													.fullDistance
											)
										}}
										км</b
									>.
								</p>

								<p
									class="route-and-flight-auction-details__route-container-second"
								>
									Ориентировочное время в пути:
									<b>{{
										data.playedFlights[index].timeSummer
									}}</b>

								<small class="mt-4 mb-4"><strong> <br> *</strong> Точное время доставки будет указано в транспортной накладной после отправки.</small>
								</p>
							</div>
						</b-col>
						<b-col xl="6">
							<div
								class="route-and-flight-auction-details__dates"
							>
								<h6
									class="
                    route-and-flight-auction-details__title
                    custom-fieldset__title
                  "
								>
									Детали рейса
								</h6>
								<p>
									На основе установленных при создании рейса
								</p>
								<div
									class="route-and-flight-auction-details__date"
								>
									<p
										class="route-and-flight-auction-details__date-title"
									>
										Плановая дата и время <b>прибытия</b> в
										первую точку маршрута:
									</p>
									<b-form-group>
										<el-date-picker-input
											id="first-point-arrival-datetime"
											v-model="
												form.playedFlights[index]
													.firstPointArrivalDatetime
											"
											type="datetime"
											placeholder=""
											format="dd.MM.yyyy HH:mm"
											:picker-options="pickerOptions"
										/>
										<small class="form-text text-muted">
											Указывайте дату и время в
											соотвествии с часовым поясом первой
											точки маршрута
										</small>
									</b-form-group>
								</div>
								<div
									class="route-and-flight-auction-details__date"
								>
									<p
										class="route-and-flight-auction-details__date-title"
									>
										Плановая дата и время начала
										<b>погрузки</b> в первой точке маршрута
									</p>
									<b-form-group>
										<el-date-picker-input
											id="first-point-loading-datetime"
											v-model="
												form.playedFlights[index]
													.firstPointLoadingDatetime
											"
											type="datetime"
											placeholder=""
											text="Указывайте дату и время в соотвествии с часовым поясом первой точки маршрута"
											format="dd.MM.yyyy HH:mm"
											:picker-options="pickerOptions"
										/>
										<small class="form-text text-muted">
											Указывайте дату и время в
											соотвествии с часовым поясом первой
											точки маршрута
										</small>
									</b-form-group>
								</div>
								<div
									class="route-and-flight-auction-details__date"
								>
									<b-form-group
										id="organizationsInputGroup"
										class="form-group-el-select"
										label="Организация"
										label-for="organizationSelect"
									>
										<el-select-clearable
											v-model="
												form.playedFlights[index]
													.organization
											"
											class="form-control"
											name="organizations"
											placeholder="Выберите организацию"
											clearable
											:disabled="
												form.playedFlights[index]
													.isDraft === false
											"
											:filterable="true"
											:remote="true"
											:remote-method="
												getOrganizationsList
											"
											:loading="loading"
											@clear="getOrganizationsList"
										>
											<el-option
												v-for="item in form
													.organizations.options"
												:key="item.value"
												:label="item.text"
												:value="item.value"
											/>
										</el-select-clearable>
									</b-form-group>
								</div>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Тип перевозки</b> —
									{{ data.playedFlights[index].type }}
								</p>
								<b-form-group label="Ускоренный">
									<b-form-checkbox
										v-model="
											form.playedFlights[index]
												.accelerated
										"
										name="accelerated"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
									label="Обязательное наличие пропуска на МКАД"
								>
									<b-form-checkbox
										v-model="
											form.playedFlights[index].mkadPass
										"
										name="mkadPass"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
									label="Обязательная постановка ранее допущенного водителя"
								>
									<b-form-checkbox
										v-model="
											form.playedFlights[index]
												.retryDriver
										"
										name="retryDriver"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
									label="Получить стоимость рейса автоматически"
								>
									<b-form-checkbox
										v-model="
											form.playedFlights[index].autoTariff
										"
										name="autoTariff"
										@change="showAutoTariffInfo(index)"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Стоимость рейса</b>
								</p>
								<b-form-group>
									<h5
										v-if="
											form.playedFlights[index].autoTariff
										"
									>
										{{
											numberRUFormat(
												form.playedFlights[index].price
											)
										}}
										руб.
									</h5>
									<b-form-input
										v-else
										v-model="
											form.playedFlights[index].price
										"
										min="0"
										type="number"
										autocomplete="false"
										@change="setPrice(index)"
										@wheel="$event.target.blur()"
									/>
								</b-form-group>
							</div>
							<div
								class="route-and-flight-auction-details__transport"
							>
								<h6
									class="
                    route-and-flight-auction-details__title
                    custom-fieldset__title
                  "
								>
									Транспорт
								</h6>
								<p>
									Укажите требования для транспортных средств
									в рейсе
								</p>
								<b-row>
									<b-col md="6">
										<!-- <b-form-group
                      v-if="form.playedFlights[index].dimension"
                      class="route-and-flight-auction-details__form-group form-group-el-select"
                      label="Габариты"
                    >
                      <el-select
                        v-model="form.playedFlights[index].dimension.value"
                        class="form-control"
                        name="dimension"
                        placeholder=""
                        clearable
                        :loading="loading"
                      >
                        <el-option
                          v-for="item in dimensionOptions"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                        />
                      </el-select>
                    </b-form-group> -->
										<b-form-group
											v-if="
												form.playedFlights[index]
													.loadingUnloading
											"
											class="
                        route-and-flight-auction-details__form-group
                        form-group-el-select
                      "
											label="Погрузка/Выгрузка"
										>
											<b-form-checkbox-group
												v-model="
													form.playedFlights[index]
														.loadingUnloading.value
												"
												name="loading-unloading"
												stacked
											>
												<b-form-checkbox
													v-for="(item,
													index) in form.loadingUnloadingOptions"
													:key="index"
													:value="item.value"
												>
													{{ item.text }}
												</b-form-checkbox>
											</b-form-checkbox-group>
										</b-form-group>
									</b-col>
									<b-col md="6">
										<b-form-group
											v-if="
												form.playedFlights[index]
													.bodyType
											"
											class="
                        route-and-flight-auction-details__form-group
                        form-group-el-select
                      "
											label="Тип кузова"
										>
											<b-form-checkbox-group
												v-model="
													form.playedFlights[index]
														.bodyType.value
												"
												name="body-type"
												stacked
											>
												<b-form-checkbox
													v-for="(item,
													index) in form.bodyTypeOptions"
													:key="index"
													:value="item.value"
												>
													{{ item.text }}
												</b-form-checkbox>
											</b-form-checkbox-group>
										</b-form-group>
									</b-col>
									<b-col md="6">
										<b-form-group
											class="
                        route-and-flight-auction-details__form-group
                        form-group-el-select
                      "
										>
											<dimensions-edit
												v-model="
													data.playedFlights[index]
														.dimensions
												"
												:route-way="
													data.playedFlights[index]
														.dimensions
												"
												:price="
													form.playedFlights[index]
														.price
												"
												:index="index"
												@input="
													getSelectedDimensions(
														$event,
														index
													)
												"
											/>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
							<div v-if="form.playedFlights[index].note.value || form.playedFlights[index].note.value === ''">
                			<div>
							<b-col xl="5" md="7" sm="8">
								<b-form-textarea
								class="mr-5 mt-5"
								id="comment-area"
								name="comment-area"
								placeholder="Пожалуйста, введите комментарий (до 200 символов)"
								no-resize
								rows="5"
								maxlength = "200"
								v-model="form.playedFlights[index].note.value"
								>
								</b-form-textarea>
								</b-col>
								<b-button
								type="button"
											variant="success"
											class="ml-1 mt-4"
								:disabled="form.playedFlights[index].note.value == ''"
								@click="updateComment(index)"
								>
								Обновить комментарий
								</b-button>

								<b-button
								type="button"
								variant="danger"
								class="ml-3 mt-4"
								@click="destroyComment(index)"
								>
								Удалить комментарий
								</b-button> 
                </div>
						</div>
						<div v-else-if="!form.playedFlights[index].note.value">
							<b-col xl="5" md="7" sm="8" v-if="form.playedFlights[index].comment.visible">
								<b-form-textarea
								class="mr-5 mt-5"
								id="comment-area"
								name="comment-area"
								placeholder="Пожалуйста, введите комментарий (до 200 символов)"
								no-resize
								rows="5"
								maxlength = "200"
								v-model="form.playedFlights[index].comment.value"
								>
								</b-form-textarea>
								</b-col>
								<b-button
								type="button"
											variant="primary"
											class="ml-1 mt-4"
								@click="addComment(index)"
								v-if="form.playedFlights[index].comment.visible === false"
								>
								<i class="fa fa-plus" />&nbsp; &nbsp; Добавить комментарий
								</b-button>

                				<b-button
								type="button"
								variant="success"
								class="ml-3 mt-4"
								v-if="form.playedFlights[index].comment.visible"
								:disabled="!form.playedFlights[index].comment.value"
								@click="saveComment(index)"
								>
								Сохранить
								</b-button>

								<b-button
								type="button"
								variant="danger"
								class="ml-3 mt-4"
								v-if="form.playedFlights[index].comment.visible"
								@click="deleteComment(index)"
								>
								Удалить комментарий
								</b-button>
						</div>
						</b-col> 
					</b-row>
				</div>
				<div id="auctionTermsAndParticipants" class="custom-fieldset">
					<h5 class="custom-fieldset__title">
						<i class="fa fa-gavel fa-lg mr-2" />Аукцион
					</h5>
					<b-tabs>
						<b-tab
							class="terms-and-participants"
							title="Условия и участники"
						>
							<b-row no-gutters>
								<b-col lg="8">
									<div
										class="terms-and-participants__contractors"
									>
										<h6
											class="terms-and-participants__title"
										>
											Участвующие перевозчики
										</h6>
										<p>
											Укажите списки перевозчиков для
											которых доступен аукцион
										</p>
										<b-form-group
											class="form-group-el-select"
										>
											<el-select
												v-model="
													form.listOfContractors.value
												"
												class="form-control"
												name="route-way"
												placeholder="Добавить группу перевозчиков"
												clearable
												reserve-keyword
												:loading="loading"
												@change="
													onContractorsListChoose(
														form.listOfContractors
															.value
													)
												"
											>
												<el-option
													v-for="item in form
														.listOfContractors
														.options"
													:key="item.value"
													:label="item.text"
													:value="item.value"
												/>
											</el-select>
										</b-form-group>
										<b-form-group
											class="form-group-el-select"
										>
											<el-select
												v-model="form.contractors.value"
												class="form-control"
												name="route-way"
												placeholder="Добавить перевозчика"
												:filterable="true"
												:remote="true"
												reserve-keyword
												:remote-method="
													contractorRemote
												"
												:loading="loading"
												@focus="contractorRemote('')"
												@change="
													onContractorChoose(
														form.contractors.value
													)
												"
											>
												<el-option
													v-for="item in form
														.contractors.options"
													:key="item.value"
													:label="item.text"
													:value="item.value"
													:disabled="item.disabled"
												/>
											</el-select>
										</b-form-group>
										<v-client-table
											id="dataTable"
											:data="contractors.items"
											:columns="contractors.columns"
											:options="contractors.options"
											:theme="contractors.theme"
										>
											<span
												slot="rating.rating"
												slot-scope="props"
											>
												<span v-if="props.row.rating"
													><span
														:class="{
															'rating-low':
																props.row.rating
																	.rating <=
																30,
															'rating-mid':
																props.row.rating
																	.rating >=
																	31 &&
																props.row.rating
																	.rating <=
																	49,
															'rating-high':
																props.row.rating
																	.rating >=
																50,
														}"
														>{{
															props.row.rating
																.rating
														}}</span
													>
												</span>
											</span>
											<span
												slot="actions"
												slot-scope="props"
											>
												<a
													v-if="
														$store.getters
															.isAdminOrManager
													"
													v-b-tooltip.hover.bottom="
														'Удалить'
													"
													class="
                            fa fa-close fa-lg
                            text-danger
                            table-action-button
                          "
													@click="
														deleteChosenContractor(
															props.row.id
														)
													"
												/>
											</span>
										</v-client-table>
										<div class="d-flex justify-content-end">
											<b-button
												type="button"
												variant="danger"
												:disabled="
													!contractors.items.length ||
														loading
												"
												:class="{ loading: loading }"
												@click="clearContractorsList()"
											>
												Очистить список перевозчиков
											</b-button>
										</div>
									</div>
								</b-col>
								<b-col lg="4">
									<div
										class="terms-and-participants__start-price"
									>
										<h6
											class="terms-and-participants__title"
										>
											Начальная цена
										</h6>
										<p>
											Укажите стартовую стоимость аукциона
											и шаг ставки
										</p>
										<b-row>
											<b-col xl="6" lg="12" md="6">
												<!-- <b-form-group>
                          <b-form-input
                            v-model="data.price"
                            type="number"
                            autocomplete="false"
                            disabled
                          />
                        </b-form-group> -->
												<h5>
													{{
														numberRUFormat(
															data.price
														)
													}}
													руб.
												</h5>
											</b-col>
											<b-col xl="6" lg="12" md="6">
												<b-form-group
													class="form-group-el-select"
													:description="
														alternativeAmountValue(
															data.price
														)
													"
												>
													<el-select
														v-model="form.VAT.value"
														class="form-control"
														name="route-way"
														:loading="loading"
													>
														<el-option
															v-for="item in form
																.VAT.options"
															:key="item.value"
															:label="item.text"
															:value="item.value"
														/>
													</el-select>
												</b-form-group>
											</b-col>
										</b-row>
										<h6
											class="terms-and-participants__title"
										>
											Шаг ставки
										</h6>
										<b-form-group>
											<b-form-input
												v-model="form.stepRate"
												type="number"
												autocomplete="false"
												@wheel="$event.target.blur()"
											/>
										</b-form-group>
										<div
											class="terms-and-participants__auction-duration"
										>
											<h6
												class="terms-and-participants__title"
											>
												Период проведения аукциона
											</h6>
											<p>
												Период проведения аукциона
												назначается непосредственно
												перед запуском. Для указания
												времени воспользуйтесь кнопкой
												"Сохранить и запустить аукцион"
											</p>
										</div>
									</div>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab title="История">
							<div class="history-auction">
								<h6 class="history-auction__title">События</h6>
								<p class="history-auction__desc">
									Список событий сформированный на протяжении
									всего существования аукциона
								</p>
								<ul class="history-auction__list">
									<li
										v-for="item in data.history"
										:key="'history-item-' + item.id"
										class="history-auction__list-item"
									>
										<span
											v-if="item.type === 'auctionCreate'"
											class="text-success"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetime(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Аукцион создан
										</span>
										<span
											v-else-if="
												item.type === 'auctionUpdate'
											"
											class="text-primary"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetime(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Аукцион обновлен:
											<ol
												v-if="item.data"
												class="history-auction__list-item-changes"
											>
												<li v-if="item.data.status">
													Аукцион переведен в статус
													"<span
														v-if="
															item.data
																.status[1] === 0
														"
														>Черновик</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 1
														"
														>Ожидает начала</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 2
														"
														>Прием ставок</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 3
														"
														>Нет победителя</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 4
														"
														>Есть победитель</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 5
														"
														>Отменен</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 6
														"
														>Утвержден</span
													>"
												</li>
												<li v-if="item.data.startDate">
													Дата начала аукциона
													изменена на "{{
														formatDatetime(
															item.data
																.startDate[1]
														)
													}}"
												</li>
												<li v-if="item.data.withNDS">
													Значение НДС изменено на "{{
														item.data.withNDS[1]
															? "С НДС"
															: "Без НДС"
													}}"
												</li>
												<li v-if="item.data.startPrice">
													Значение начальной стоимости
													изменено на "{{
														item.data.startPrice[1]
													}}
													руб."
												</li>
												<li v-if="item.data.step">
													Значение шага изменено на
													"{{ item.data.step[1] }}
													руб."
												</li>
												<li v-if="item.data.dimension">
													<span
														v-if="
															item.data
																.dimension[0]
														"
														>Значение габаритов
														очищено</span
													>
													<span v-else
														>Значение габаритов
														изменено на "{{
															item.data
																.dimension[1]
																.name
														}}"</span
													>
												</li>
												<li
													v-if="
														item.data.loadingTypes
													"
												>
													Значение погрузки/выгрузки
													изменено на "{{
														item.data.loadingTypes
													}}"
												</li>
												<li v-if="item.data.bodyTypes">
													Значение типов кузовов
													изменено на "{{
														item.data.bodyTypes
													}}"
												</li>
												<li v-if="item.data.offerDate">
													Дата начала подачи встречных
													предложений изменена на "{{
														formatDatetime(
															item.data
																.offerDate[1]
														)
													}}"
												</li>
											</ol>
										</span>
										<span
											v-else-if="
												item.type ===
													'changeLoadingTypes'
											"
											class="text-primary"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetime(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Аукцион обновлен:
											<ol
												class="history-auction__list-item-changes"
											>
												<li v-if="item.data">
													Значение погрузки/выгрузки
													изменено на: "<span
														v-for="(loadingType,
														index) in item.data"
														:key="
															'loading-type-' +
																loadingType.id
														"
														>{{ loadingType.name
														}}{{
															Number(index) !==
															item.data.length - 1
																? ", "
																: ""
														}}</span
													>"
												</li>
											</ol>
										</span>
										<span
											v-else-if="
												item.type ===
													'changeVehicleBodyTypes'
											"
											class="text-primary"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetime(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Аукцион обновлен:
											<ol
												v-if="item.data"
												class="history-auction__list-item-changes"
											>
												<li v-if="item.data">
													Значение типов кузовов
													изменено на: "<span
														v-for="(bodyType,
														index) in item.data"
														:key="
															'body-type-' +
																bodyType.id
														"
														>{{ bodyType.name
														}}{{
															Number(index) !==
															item.data.length - 1
																? ", "
																: ""
														}}</span
													>"
												</li>
											</ol>
										</span>
									</li>
								</ul>
							</div>
						</b-tab>
					</b-tabs>
				</div>
			</div>
			<div class="fixed-buttons">
				<b-button
					type="button"
					variant="secondary"
					class="mr-2"
					:class="{ loading: loading }"
					@click="$router.go(-1)"
				>
					Назад
				</b-button>
				<b-button
					type="submit"
					variant="primary"
					class="mr-2"
					:disabled="loading"
					:class="{ loading: loading }"
				>
					Сохранить изменения
				</b-button>
				<b-button
					type="button"
					variant="success"
					:disabled="loading"
					:class="{ loading: loading }"
					@click="startAuctionModalShow()"
				>
					Сохранить и запустить аукцион
				</b-button>
			</div>
		</b-form>

		<b-modal
			v-model="startAuctionModal.show"
			:title="'Запуск аукциона'"
			class="modal-warning"
			cancel-title="Назад"
			ok-variant="success"
			:ok-disabled="startAuctionDisabled"
			ok-title="Запустить аукцион"
			@ok="onStart()"
		>
			<p>Выберите тип запуска аукциона и назначьте время</p>
			<b-form-group class="form-group-el-select" label="Способ запуска">
				<el-select
					v-model="startAuctionModal.method.value"
					class="form-control"
					name="route-way"
					:loading="loading"
					@change="startAuctionAutoDateSet()"
				>
					<el-option
						v-for="item in startAuctionModal.method.options"
						:key="item.value"
						:label="item.text"
						:value="item.value"
					/>
				</el-select>
			</b-form-group>
			<b-form-group
				v-if="startAuctionModal.method.value"
				label="Старт аукциона"
				label-for="start-auction-modal-start-date"
			>
				<el-date-picker-input
					id="start-auction-modal-start-date"
					v-model="startAuctionModal.startDate"
					type="datetime"
					placeholder=""
					format="dd.MM.yyyy HH:mm"
					:picker-options="pickerOptions"
					@change="startAuctionStartDateChange()"
				/>
			</b-form-group>
			<b-form-group
				label="Окончание приема ставок"
				label-for="start-auction-modal-end-date"
			>
				<el-date-picker-input
					id="start-auction-modal-end-date"
					v-model="startAuctionModal.stopDate"
					type="datetime"
					placeholder=""
					format="dd.MM.yyyy HH:mm"
					:picker-options="pickerOptions"
				/>
			</b-form-group>

			<b-form-group>
				<b-form-radio-group
					stacked
					v-model="startAuctionModal.selected"
				>
					<b-form-radio
						class="mb-3"
						value="standartAuction"
						id="standartAuction"
						name="standart-auction"
						@change="startAuctionAutoDateSet()"
						>Стандартный запуск аукциона</b-form-radio
					>
					<b-form-radio
						class="mb-3"
						value="firstBidWin"
						id="firstBidWin"
						name="first-bid-win"
						@change="startAuctionAutoDateSet()"
						>Розыгрыш победителем по первой ставке</b-form-radio
					>
					<b-form-radio
						value="enableOffers"
						id="enableOffers"
						name="enable-offers"
						@change="startAuctionAutoDateSet()"
						>Возможность подачи встречных предложений</b-form-radio
					>
				</b-form-radio-group>
			</b-form-group>

			<b-form-group v-if="startAuctionModal.selected === 'enableOffers'">
				<el-date-picker-input
					v-model="startAuctionModal.offerDate"
					type="datetime"
					format="dd.MM.yyyy HH:mm"
					placeholder="Дата и время начала подачи встречных предложений"
					:picker-options="pickerOptions"
				/>
				<small class="form-text text-muted"
					>Оставьте поле пустым если хотите получать втречные
					предложения на протяжении всего аукциона</small
				>
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>
import ElDatePickerInput from "../../components/ElDatePickerInput.vue";
import ElSelectClearable from "../../components/ElSelectClearable.vue";
import DimensionsEdit from "../../components/DimensionsEdit.vue";
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import notifications from "../../components/mixins/notifications";
import {
	customersRoutesForAuctions,
	customersContractorsListsList,
	customersAllContractorsList,
	transportVehiclesAuctionBodyTypes,
	transportVehiclesLoadingTypes,
	transportVehiclesDimensionsTypes,
	customersAuctionRead,
	customersRouteRead,
	routeWayRead,
	customersContractorsListRead,
	customersContractorRead,
	customersAuctionUpdate,
	customersAuctionRun,
	organizationsList,
  sendAuctionComment,
  sendUpdatedComment,
  sendDestroyComment,
} from "../../services/api";
import {
	valueToValueWithVAT,
	valueToValueWithoutVAT,
	numberToRUFormat,
} from "@/components/mixins/helpers";
import moment from "moment";
import tz from "moment-timezone";

Vue.use(ClientTable);

export default {
	name: "CustomerAuctionsEdit",
	components: {
		ClientTable,
		Event,
		ElSelectClearable,
		ElDatePickerInput,
		DimensionsEdit,
	},
	mixins: [validationMixin, notifications],
	props: ["auctionsId"],
	data() {
		return {
			data: {
				history: null,
				defaultBodyType: [],
				defaultLoadingUnloading: [],
				defaultDimension: null,
				playedFlights: {},
				price: 0,
				currentPrices: [],
			},
			auctionRoutes: {},
			routes: {},
      route: null,
      routeInfo: null,
			dimensionOptions: [],
			loadingUnloadingOptions: [],
			bodyTypeOptions: [],
			form: {
				playedFlightsSelect: {
					options: [],
					value: [],
				},
				organizations: {
					value: null,
					options: [],
				},
				playedFlights: {
					comments: {
						id: null,
						value: null,
					},
					comment: {
					visible: false,
					value: null,
					},
				},
				listOfContractors: {
					options: [],
					value: null,
				},
				contractors: {
					options: [],
					value: null,
				},
				VAT: {
					options: [
						{ value: 0, text: "Без НДС" },
						{ value: 1, text: "С НДС" },
					],
					value: 1,
				},
				startPrice: null,
				stepRate: 500,
			},
			contractors: {
				columns: [
					"id",
					"name",
					"email",
					"trucksCount",
					"rating.rating",
					"actions",
				],
				options: {
					headings: {
						id: "ID",
						name: "Название",
						email: "Email",
						trucksCount: "Кол-во автомобилей",
						"rating.rating": "Рейтинг",
						actions: "",
					},
					sortable: [
						"id",
						"name",
						"email",
						"trucksCount",
						"rating.rating",
					],
					filterable: [],
					sortIcon: {
						base: "fa",
						up: "fa-sort-asc",
						down: "fa-sort-desc",
						is: "fa-sort",
					},
					pagination: {
						chunk: 10,
						edge: true,
						nav: "fixed",
					},
					perPage: 5,
					perPageValues: [5, 10, 25],
					texts: {
						count:
							"Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись",
						first: "Первая",
						last: "Последняя",
						filter: "Фильтр:",
						filterPlaceholder: "Поисковый запрос",
						limit: "Записи:",
						page: "Страница:",
						noResults: "Нет совпадающих записей",
						filterBy: "Фильтр по {column}",
						loading: "Загрузка...",
						defaultOption: "Выбор {column}",
						columns: "Столбцы",
					},
				},
				items: [],
				theme: "bootstrap4",
			},
			pickerOptions: {
				firstDayOfWeek: 1,
			},
			startAuctionModal: {
				show: false,
				selected: "standartAuction",
				method: {
					options: [
						{ value: 0, text: "Запустить сейчас" },
						{ value: 1, text: "Запустить как отложенный" },
					],
					value: 0,
				},
				startDate: "",
				stopDate: "",
				offerDate: null,
			},
			loading: false,
			useVuex: false,
			template: "default",
		};
	},
	computed: {
		startAuctionDisabled() {
			if (this.startAuctionModal.method.value) {
				return !(
					this.startAuctionModal.startDate &&
					this.startAuctionModal.stopDate
				);
			} else {
				return !this.startAuctionModal.stopDate;
			}
		},
		formStrEdit() {
			const calculationFormData = {};
			if (Object.keys(this.form.playedFlights).length > 0) {
				calculationFormData.auctionRoutes = this.form.playedFlightsSelect.value.map(
					(routeId) => {
						const auctionRoute = {};
						auctionRoute.route = { id: routeId };
						if (
							this.form.playedFlights[routeId]
								.firstPointArrivalDatetime
						) {
							auctionRoute.route.planDateOfFirstPointArrive = moment(
								this.form.playedFlights[routeId]
									.firstPointArrivalDatetime
							)
								.tz(
									this.data.playedFlights[routeId]
										.firstRouteWayTimezone,
									true
								)
								.format();
						}
						if (
							this.form.playedFlights[routeId]
								.firstPointLoadingDatetime
						) {
							auctionRoute.route.planDateOfFirstPointLoading = moment(
								this.form.playedFlights[routeId]
									.firstPointLoadingDatetime
							)
								.tz(
									this.data.playedFlights[routeId]
										.firstRouteWayTimezone,
									true
								)
								.format();
						}
						auctionRoute.id = this.data.playedFlights[
							routeId
						].auctionRouteId;
						if (this.form.playedFlights[routeId].organization) {
							auctionRoute.route.organization = {
								id: this.form.playedFlights[routeId]
									.organization,
							};
						}
						auctionRoute.autoTariff = this.form.playedFlights[
							routeId
						].autoTariff;
						auctionRoute.startPrice = this.form.playedFlights[
							routeId
						].price;
						auctionRoute.route.boostFlag = this.form.playedFlights[
							routeId
						].accelerated;
						auctionRoute.mkadPass = this.form.playedFlights[
							routeId
						].mkadPass;
						auctionRoute.retryDriver = this.form.playedFlights[
							routeId
						].retryDriver;
						// if (this.form.playedFlights[routeId].dimension.value) {
						//   auctionRoute.dimension = {id: this.form.playedFlights[routeId].dimension.value};
						// }
						if (this.form.playedFlights[routeId].dimension) {
							auctionRoute.dimension = this.form.playedFlights[
								routeId
							].dimension;
						}
						if (this.form.playedFlights[routeId].comment.value && this.form.playedFlights[routeId].comment.value !== "") {
							this.form.playedFlights[routeId].note.value = this.form.playedFlights[routeId].comment.value;
						}
						if (
							this.form.playedFlights[routeId].optionalDimensions
						) {
							auctionRoute.optionalDimensions = this.form.playedFlights[
								routeId
							].optionalDimensions;
						}
						auctionRoute.loadingTypes = this.form.playedFlights[
							routeId
						].loadingUnloading.value.map((item) => {
							return { id: item };
						});
						auctionRoute.bodyTypes = this.form.playedFlights[
							routeId
						].bodyType.value.map((item) => {
							return { id: item };
						});
						return auctionRoute;
					}
				);
				if (this.startAuctionModal.startDate) {
					calculationFormData.startDate = moment(
						this.startAuctionModal.startDate
					).format();
				}
				if (this.startAuctionModal.stopDate) {
					calculationFormData.stopDate = moment(
						this.startAuctionModal.stopDate
					).format();
				}
				calculationFormData.possibleParticipants = this.contractors.items.map(
					(contractor) => {
						return { id: contractor.id };
					}
				);
				if (this.form.stepRate) {
					calculationFormData.step = this.form.stepRate;
				}
				calculationFormData.withNDS = !!this.form.VAT.value;
			}
			return calculationFormData;
		},
		formStrStart() {
			const calculationFormData = {};
			if (Object.keys(this.form.playedFlights).length > 0) {
				calculationFormData.auctionRoutes = this.form.playedFlightsSelect.value.map(
					(routeId) => {
						const auctionRoute = {};
						auctionRoute.route = { id: routeId };
						if (
							this.form.playedFlights[routeId]
								.firstPointArrivalDatetime
						) {
							auctionRoute.route.planDateOfFirstPointArrive = moment(
								this.form.playedFlights[routeId]
									.firstPointArrivalDatetime
							)
								.tz(
									this.data.playedFlights[routeId]
										.firstRouteWayTimezone,
									true
								)
								.format();
						}
						if (
							this.form.playedFlights[routeId]
								.firstPointLoadingDatetime
						) {
							auctionRoute.route.planDateOfFirstPointLoading = moment(
								this.form.playedFlights[routeId]
									.firstPointLoadingDatetime
							)
								.tz(
									this.data.playedFlights[routeId]
										.firstRouteWayTimezone,
									true
								)
								.format();
						}
						auctionRoute.id = this.data.playedFlights[
							routeId
						].auctionRouteId;
						if (this.form.playedFlights[routeId].organization) {
							auctionRoute.route.organization = {
								id: this.form.playedFlights[routeId]
									.organization,
							};
						}
						auctionRoute.startPrice = this.form.playedFlights[
							routeId
						].price;
						auctionRoute.route.boostFlag = this.form.playedFlights[
							routeId
						].accelerated;
						auctionRoute.mkadPass = this.form.playedFlights[
							routeId
						].mkadPass;
						auctionRoute.retryDriver = this.form.playedFlights[
							routeId
						].retryDriver;
						// if (this.form.playedFlights[routeId].dimension.value) {
						//   auctionRoute.dimension = {id: this.form.playedFlights[routeId].dimension.value};
						// }
						if (this.form.playedFlights[routeId].dimension) {
							auctionRoute.dimension = this.form.playedFlights[
								routeId
							].dimension;
						}
						if (this.form.playedFlights[routeId].comment.value && this.form.playedFlights[routeId].comment.value !== "") {
              this.form.playedFlights[routeId].note.value = this.form.playedFlights[routeId].comment.value;
						}
						if (
							this.form.playedFlights[routeId].optionalDimensions
						) {
							auctionRoute.optionalDimensions = this.form.playedFlights[
								routeId
							].optionalDimensions;
						}
						auctionRoute.loadingTypes = this.form.playedFlights[
							routeId
						].loadingUnloading.value.map((item) => {
							return { id: item };
						});
						auctionRoute.bodyTypes = this.form.playedFlights[
							routeId
						].bodyType.value.map((item) => {
							return { id: item };
						});
						return auctionRoute;
					}
				);
				calculationFormData.possibleParticipants = this.contractors.items.map(
					(contractor) => {
						return { id: contractor.id };
					}
				);
				if (this.form.startPrice) {
					calculationFormData.startPrice = this.form.startPrice;
				}
				if (this.form.stepRate) {
					calculationFormData.step = this.form.stepRate;
				}
				calculationFormData.withNDS = !!this.form.VAT.value;
			}
			if (
				this.startAuctionModal.method.value &&
				this.startAuctionModal.startDate
			) {
				calculationFormData.startDate = moment(
					this.startAuctionModal.startDate
				).format();
			}
			if (this.startAuctionModal.stopDate) {
				calculationFormData.stopDate = moment(
					this.startAuctionModal.stopDate
				).format();
			}
			if (this.startAuctionModal.selected === "firstBidWin") {
				calculationFormData.firstBidWin =
					this.startAuctionModal.selected === "firstBidWin";
			}
			if (this.startAuctionModal.selected === "enableOffers") {
				calculationFormData.enableOffers =
					this.startAuctionModal.selected === "enableOffers";
			}
			if (this.startAuctionModal.offerDate) {
				calculationFormData.offerDate = moment(
					this.startAuctionModal.offerDate
				).format();
			}
			return calculationFormData;
		},
	},
	mounted() {
		this.contractorRemote("");
		this.customersContractorsListsList();
		this.transportVehiclesAuctionBodyTypes();
		this.transportVehiclesLoadingTypes();
		// this.transportVehiclesDimensionsTypes();
		this.customersAuctionRead();
		this.getOrganizationsList();
	},
	methods: {
		formatDate(date) {
			return date ? moment(date).format("DD.MM.YYYY") : "";
		},
		formatTime(date) {
			return date ? moment(date).format("HH:mm") : "";
		},
		formatDatetime(date) {
			return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
		},
		showAutoTariffInfo(index) {
			if (this.form.playedFlights[index].autoTariff) {
				this.showInfo(
					"Внимание, стоимость будет пересчитана на базовую после сохранения аукциона"
				);
			}
		},
    addComment(index) {
		this.form.playedFlights[index].comment.visible = true;
    },
    deleteComment(index) {
		this.form.playedFlights[index].comment.visible = false;
		this.form.playedFlights[index].comment.value = null;
    },
    async saveComment(index) {
      if (this.route) {
        this.route.forEach((route) => {
          if (route.id == index) {
            this.routeInfo = route;
          }
        });
      }
      let body = {
        comment: this.form.playedFlights[index].comment.value,
        route: this.routeInfo,
      };
      const response = await sendAuctionComment(this.$store.state.user.ownerId, body);
	if (response && response.status === 200) {
		this.showSuccess("Комментарий сохранён");
		if (response.data.id) {
			this.form.playedFlights[index].note.id = response.data.id;
		}
		if (this.form.playedFlights[index].comment.value) {
			this.form.playedFlights[index].note.value = this.form.playedFlights[index].comment.value;
		}
	}
    },

    async updateComment(index) {
      let commentId =  this.form.playedFlights[index].note.id;
      let body = {
        comment: null,
      };
      if (this.form.playedFlights[index].note.value && this.form.playedFlights[index].note.value !== undefined && this.form.playedFlights[index].note.value !== '') {
        body["comment"] = this.form.playedFlights[index].note.value;
      }
      const response = await sendUpdatedComment(this.$store.state.user.ownerId, commentId, body);
	  if (response && response.status === 200 || response.status === 201) {
		this.showSuccess("Комментарий обновлен");
	  }
    },
    async destroyComment(index) {
      let commentId =  this.form.playedFlights[index].note.id;
      const response = await sendDestroyComment(this.$store.state.user.ownerId, commentId);
	  this.form.playedFlights[index].note.value = null;
      this.form.playedFlights[index].note.id = null;
      this.form.playedFlights[index].comment.visible = false;
	  if (response && response.status === 200 || response.status === 201) {
		this.showInfo("Комментарий удалён");
	  }
    },
		setPrice(index) {
			this.data.currentPrices[index] = this.form.playedFlights[
				index
			].price;
			this.data.price = this.data.currentPrices.reduce(
				(a, b) => +a + +b,
				0
			);
		},
		startAuctionModalShow() {
			this.startAuctionModal.show = true;
			this.startAuctionAutoDateSet();
		},
		getSelectedDimensions(value, index) {
			if (value.dimension) {
				Vue.set(
					this.form.playedFlights[index],
					"dimension",
					value.dimension
				);
			}
			if (value.optionalDimensions) {
				Vue.set(
					this.form.playedFlights[index],
					"optionalDimensions",
					value.optionalDimensions
				);
			}
		},
		startAuctionAutoDateSet() {
			if (this.startAuctionModal.selected === "enableOffers") {
				if (!this.startAuctionModal.method.value) {
					this.startAuctionModal.stopDate = moment()
						.add(60, "m")
						.format();
				} else {
					this.startAuctionModal.stopDate = moment(
						this.startAuctionModal.startDate
					)
						.add(60, "m")
						.format();
				}
			} else {
				if (!this.startAuctionModal.method.value) {
					this.startAuctionModal.stopDate = moment()
						.add(30, "m")
						.format();
				} else {
					this.startAuctionModal.stopDate = moment(
						this.startAuctionModal.startDate
					)
						.add(30, "m")
						.format();
				}
			}
		},
		startAuctionStartDateChange() {
			if (this.startAuctionModal.startDate) {
				this.startAuctionAutoDateSet();
			}
		},
		alternativeAmountValue(vat, value) {
			if (value) {
				if (!vat) {
					return (
						"(" +
						this.numberRUFormat(valueToValueWithVAT(value)) +
						" руб. С НДС)"
					);
				} else {
					return (
						"(" +
						this.numberRUFormat(valueToValueWithoutVAT(value)) +
						" руб. Без НДС)"
					);
				}
			} else {
				return "";
			}
		},
		numberRUFormat(value) {
			return numberToRUFormat(value);
		},
		clearContractorsList() {
			this.contractors.items = [];
		},
		async contractorRemote(query) {
			this.loading = true;
			const response = await customersAllContractorsList(
				this.$store.state.user.ownerId,
				{
					valid: true,
					name: query,
					limit: 100,
				}
			);
			if (response && response.status === 200) {
				this.form.contractors.options = response.data.items.map(
					(item) => {
						return {
							value: item.contractor.id,
							text: item.contractor.name,
						};
					}
				);
			}
			this.updateContractorOptions();
			this.loading = false;
		},
		updateContractorOptions() {
			this.form.contractors.options = this.form.contractors.options.map(
				(item) => {
					return {
						...item,
						...{ disabled: !!this.inContractorsList(item.value) },
					};
				}
			);
		},
		inContractorsList(id) {
			return this.contractors.items.find((contractor) => {
				return contractor.id === id;
			});
		},
		async getOrganizationsList(name) {
			this.loading = true;
			const params = { name: name, limit: 20 };
			const response = await organizationsList(params);
			if (response && response.status === 200) {
				this.form.organizations.options = response.data.items.map(
					(item) => {
						return { value: item.id, text: item.name };
					}
				);
			}
			this.loading = false;
		},
		async customersAuctionRead() {
			this.loading = true;
			const response = await customersAuctionRead(
				this.$store.state.user.ownerId,
				this.auctionsId
			);
			if (response && response.status === 200) {
        if (response.data.routes) {
          this.route = [];
          response.data.routes.forEach((route) => {
            this.route.push(route);
          });
        }
				// playedFlightsSelect start
				this.form.playedFlightsSelect.value = response.data.auctionRoutes.map(
					(auctionRoute) => auctionRoute.route.id
				);
				this.form.playedFlightsSelect.options = response.data.auctionRoutes.map(
					(auctionRoute) => {
						return {
							value: auctionRoute.route.id,
							text: auctionRoute.route.routeCode,
						};
					}
				);
				// playedFlightsSelect end
				// auction start
				if (response.data.offerDate) {
					this.startAuctionModal.offerDate = response.data.offerDate;
				}
				if (response.data.startPrice) {
					this.form.startPrice = response.data.startPrice;
					this.data.price = response.data.startPrice;
				}
				if (response.data.step) {
					this.form.stepRate = response.data.step;
				}
				if (response.data.withNDS) {
					this.form.VAT.value = 1;
				} else {
					this.form.VAT.value = 0;
				}
				if (response.data.auctionHistoryMessages) {
					this.data.history = response.data.auctionHistoryMessages.sort(
						(a, b) => b.id - a.id
					);
				}
				if (response.data.possibleParticipants.length > 0) {
					this.contractors.items = response.data.possibleParticipants;
        }      
				// auction end
				// auctionRoute start
				response.data.auctionRoutes.forEach((auctionRoute) => {
					this.auctionRoutes[auctionRoute.id] = auctionRoute;
					Vue.set(this.data.playedFlights, auctionRoute.route.id, {});
					Vue.set(this.form.playedFlights, auctionRoute.route.id, {});
					this.data.playedFlights[
						auctionRoute.route.id
					].dimensions = {
						id: null,
						dimension: null,
						optionalDimensions: [],
					};
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"auctionRouteId",
						auctionRoute.id
					);
					this.customersRouteRead(auctionRoute.route.id);
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id]
							.dimensions,
						"id",
						auctionRoute.route.routeWay.id
					);
					if (auctionRoute.dimension) {
						Vue.set(
							this.data.playedFlights[auctionRoute.route.id]
								.dimensions,
							"dimension",
							auctionRoute.dimension
						);
					}
					if (auctionRoute.optionalDimensions) {
						Vue.set(
							this.data.playedFlights[auctionRoute.route.id]
								.dimensions,
							"optionalDimensions",
							auctionRoute.optionalDimensions
						);
					}
					if (auctionRoute.loadingTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"loadingUnloading",
							{
								value: auctionRoute.loadingTypes.map(
									(item) => item.id
								),
							}
						);
					} else {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"loadingUnloading",
							{ value: this.data.defaultLoadingUnloading }
						);
					}
					if (auctionRoute.bodyTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"bodyType",
							{
								value: auctionRoute.bodyTypes.map(
									(item) => item.id
								),
							}
						);
					} else {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"bodyType",
							{ value: this.data.defaultBodyType }
						);
					}
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"mkadPass",
						auctionRoute.mkadPass
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"retryDriver",
						auctionRoute.retryDriver
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"comment",
						{visible: false,
						value: null}
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"note",
						{id: null,
						value: null}
					)
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"autoTariff",
						auctionRoute.autoTariff
					);
					if (auctionRoute.startPrice) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"price",
							auctionRoute.startPrice
						);
					} else {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"price",
							0
						);
					}
				});
			if (response.data.routes) {
            response.data.routes.forEach((route) => {
				if (route.comment) {
					Vue.set(this.form.playedFlights[route.id], "note", {value: route.comment.comment, id: route.comment.id});
				}
            });       
        }
				setTimeout(() => {
					if (this.$route.hash === "#auctionTermsAndParticipants") {
						this.$el
							.querySelector("#auctionTermsAndParticipants")
							.scrollIntoView({ behavior: "smooth" });
					}
				}, 0);
				// auctionRoute end
			}
			this.loading = false;
		},
		async customersRoutesForAuctions(name) {
			this.loading = true;
			const options = {};
			this.form.playedFlightsSelect.value.map((item, index) => {
				options["ids[" + index + "]"] = item;
			});
			options.name = name;
			options.limit = 9999;
			options.auction_id = this.auctionsId;
			const response = await customersRoutesForAuctions(
				this.$store.state.user.ownerId,
				options
			);
			if (response && response.status === 200) {
				this.form.playedFlightsSelect.options = response.data.items.map(
					(item) => {
						return { value: item.id, text: item.routeCode };
					}
				);
			}
			this.loading = false;
		},
		async customersContractorsListsList() {
			this.loading = true;
			const response = await customersContractorsListsList(
				this.$store.state.user.ownerId,
				{ limit: 9999 }
			);
			if (response && response.status === 200) {
				this.form.listOfContractors.options = response.data.items.map(
					(item) => {
						return { value: item.id, text: item.name };
					}
				);
			}
			this.loading = false;
		},
		async transportVehiclesAuctionBodyTypes() {
			this.loading = true;
			const response = await transportVehiclesAuctionBodyTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.bodyTypeOptions = response.data.items.map((item) => {
					if (item.name === "Тент с усиленной обрешеткой") {
						this.data.defaultBodyType.push(item.id);
					}
					return { value: item.id, text: item.name };
				});
			}
			this.loading = false;
		},
		async transportVehiclesLoadingTypes() {
			this.loading = true;
			const response = await transportVehiclesLoadingTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.loadingUnloadingOptions = response.data.items.map(
					(item) => {
						this.data.defaultLoadingUnloading.push(item.id);
						return { value: item.id, text: item.name };
					}
				);
			}
			this.loading = false;
		},
		// async transportVehiclesDimensionsTypes() {
		//   this.loading = true;
		//   const response = await transportVehiclesDimensionsTypes({limit: 100});
		//   if (response && response.status === 200) {
		//     this.dimensionOptions = response.data.items.map((item) => {
		//       if (item.name === '20 т(80-93 м3)') {
		//         this.data.defaultDimension = item.id;
		//       }
		//       return {value: item.id, text: item.name};
		//     });
		//   }
		//   this.loading = false;
		// },
		async customersRoutesRead(routesIds) {
			routesIds.forEach((routeId) => {
				if (!this.data.playedFlights[routeId]) {
					Vue.set(this.data.playedFlights, routeId, {});
					Vue.set(this.form.playedFlights, routeId, {});
					this.customersRouteRead(routeId);
					// Vue.set(this.form.playedFlights[routeId], 'dimension', {value: this.data.defaultDimension});
					Vue.set(
						this.form.playedFlights[routeId],
						"loadingUnloading",
						{
							value: this.data.defaultLoadingUnloading,
						}
					);
					Vue.set(this.form.playedFlights[routeId], "bodyType", {
						value: this.data.defaultBodyType,
					});
					Vue.set(
						this.form.playedFlights[routeId],
						"mkadPass",
						false
					);
					Vue.set(
						this.form.playedFlights[routeId],
						"retryDriver",
						false
					);
					Vue.set(this.form.playedFlights[routeId], "price", 0);
					Vue.set(
						this.form.playedFlights[routeId],
						"comment",
						{visible: false,
						value: null}
					)
					Vue.set(
						this.form.playedFlights[routeId],
						"note",
						{id: null,
						value: null}
					)
					Vue.set(
						this.form.playedFlights[routeId],
						"autoTariff",
						true
					);
				}
			});
			for (const index in this.data.playedFlights) {
				if (!(routesIds.indexOf(Number(index)) != -1)) {
					delete this.data.playedFlights[index];
				}
			}
		},
		async customersRouteRead(routeId) {
			this.loading = true;
			if (routeId) {
				const response = await customersRouteRead(
					this.$store.state.user.ownerId,
					routeId
				);
				if (response && response.status === 200) {
					if (
						response.data.auctionRoute &&
						response.data.auctionRoute.dimension
					) {
						Vue.set(
							this.data.playedFlights[routeId].dimensions,
							"id",
							response.data.routeWay.id
						);
						Vue.set(
							this.data.playedFlights[routeId].dimensions,
							"dimension",
							response.data.auctionRoute.dimension
						);
					}
					if (
						response.data.auctionRoute &&
						response.data.auctionRoute.optionalDimensions
					) {
						Vue.set(
							this.data.playedFlights[routeId].dimensions,
							"optionalDimensions",
							response.data.auctionRoute.optionalDimensions
						);
					}
					Vue.set(
						this.data.playedFlights[routeId],
						"routeCode",
						response.data.routeCode
					);
					if (response.data.comment && (response.data.comment.comment !== undefined || response.data.comment.comment !== '')) {
						Vue.set(
						this.form.playedFlights[routeId],
						"note",
						{id: response.data.comment.id,
						value: response.data.comment.comment}
					)
					}
					Vue.set(
						this.data.playedFlights[routeId],
						"type",
						response.data.transportation.name
					);
					const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
						.timeZone;
					if (response.data.planDateOfFirstPointArrive) {
						Vue.set(
							this.form.playedFlights[routeId],
							"firstPointArrivalDatetime",
							moment(
								response.data.planDateOfFirstPointArrive,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format()
						);
					}

					Vue.set(
						this.data.playedFlights[routeId],
						"movementPoints",
						response.data.movementPoints.sort(
							(a, b) => a.rowNumber - b.rowNumber
						)
					);

					Vue.set(
						this.data.playedFlights[routeId],
						"guid",
						response.data.transportation.guid
					);

					if (response.data.planDateOfFirstPointLoading) {
						Vue.set(
							this.form.playedFlights[routeId],
							"firstPointLoadingDatetime",
							moment(
								response.data.planDateOfFirstPointLoading,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format()
						);
					}
					Vue.set(
						this.form.playedFlights[routeId],
						"accelerated",
						response.data.boostFlag
					);
					if (response.data.organization) {
						Vue.set(
							this.form.playedFlights[routeId],
							"organization",
							response.data.organization.id
						);
					}
					Vue.set(
						this.form.playedFlights[routeId],
						"isDraft",
						response.data.isDraft
					);
					await this.onRouteChange(
						response.data.routeWay.id,
						routeId
					);
				}
			}
			this.loading = false;
		},
		async onRouteChange(value, routeId) {
			this.loading = true;
			const response = await routeWayRead(value);
			if (response && response.status === 200) {
				Vue.set(
					this.data.playedFlights[routeId],
					"points",
					response.data.routeWayPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					)
				);
				let lowestPoint = null;
				response.data.routeWayPoints.forEach((point) => {
					if (
						!lowestPoint ||
						point.rowNumber < lowestPoint.rowNumber
					) {
						lowestPoint = point;
					}
				});
				if (lowestPoint) {
					Vue.set(
						this.data.playedFlights[routeId],
						"firstRouteWayTimezone",
						lowestPoint.department.timeZone
					);
				}
				Vue.set(
					this.data.playedFlights[routeId],
					"fullDistance",
					response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return accumulator + Number(currentValue.distance);
						}, 0)
						.toFixed(2)
				);

				if (
					this.data.playedFlights[routeId].guid ===
						"4b714ab4-4c44-4578-9a7a-06dbe77d8c45" ||
					this.data.playedFlights[routeId].guid ===
						"9ec087d0-917c-4fbb-b714-80ab7603fd6e"
				) {
					this.data.playedFlights[routeId].movementPoints.forEach(
						(point, index) => {
							if (
								this.data.playedFlights[routeId].movementPoints[
									index
								].pointAddress &&
								this.data.playedFlights[routeId].movementPoints[
									index
								].pointAddress !== ""
							) {
								this.data.playedFlights[routeId].points[
									index
								].department.mainWarehouse.address = this.data.playedFlights[
									routeId
								].movementPoints[index].pointAddress;
							}
						}
					);
				}

				Vue.set(
					this.data.playedFlights[routeId],
					"timeSummer",
					response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return (
								accumulator + Number(currentValue.timeSummer)
							);
						}, 0)
						.toFixed(2)
				);
			}
			this.loading = false;
		},
		async onContractorsListChoose(listId) {
			this.loading = true;
			const response = await customersContractorsListRead(
				this.$store.state.user.ownerId,
				listId
			);
			if (response && response.status === 200) {
				response.data.contractors.forEach((contractor) => {
					if (
						contractor.customers &&
						contractor.customers.length > 0 &&
						contractor.customers[0].rating
					) {
						contractor.rating = contractor.customers[0].rating;
					}
					this.contractors.items = this.contractors.items.filter(
						(x) => x.id !== contractor.id
					);
					this.contractors.items.unshift(contractor);
				});
				this.form.listOfContractors.value = null;
			}
			this.loading = false;
		},
		async onContractorChoose(contractorId) {
			this.loading = true;
			const response = await customersContractorRead(
				this.$store.state.user.ownerId,
				contractorId
			);
			if (response && response.status === 200) {
				response.data.contractor.rating = response.data.rating;
				this.contractors.items = this.contractors.items.filter(
					(x) => x.id !== response.data.contractor.id
				);
				this.contractors.items.unshift(response.data.contractor);
				this.form.contractors.value = null;
			}
			this.updateContractorOptions();
			this.loading = false;
		},
		deleteChosenContractor(contractorId) {
			this.contractors.items = this.contractors.items.filter(
				(item) => item.id !== contractorId
			);
		},
		async onSubmit() {
			this.loading = true;
			const response = await customersAuctionUpdate(
				this.$store.state.user.ownerId,
				this.auctionsId,
				this.formStrEdit
			);
			if (response && response.status === 200) {
				this.showSuccess("Аукцион обновлен");
				this.$router.go();
			}
			this.loading = false;
		},
		async onStart() {
			this.loading = true;
			const response = await customersAuctionRun(
				this.$store.state.user.ownerId,
				this.auctionsId,
				this.formStrStart
			);
			if (response && response.status === 200) {
				this.showSuccess("Аукцион подготовлен к запуску");
				this.$router.push({
					path: `/customers/id${this.$store.state.user.ownerId}/auction/id${response.data.id}`,
				});
			}
			this.loading = false;
		},
		findFirstError(component = this) {
			if (component.state === false) {
				if (component.$refs.input) {
					component.$refs.input.focus();
					return true;
				}
				if (component.$refs.check) {
					component.$refs.check.focus();
					return true;
				}
			}
			let focused = false;
			component.$children.some((child) => {
				focused = this.findFirstError(child);
				return focused;
			});

			return focused;
		},
	},
};
</script>

<style lang="scss">
.history-auction {
	&__title {
	}
	&__desc {
	}
	&__list {
		list-style: inside none;
		overflow: auto;
		height: 444px;
		padding: 10px 5px;
		margin-bottom: 0;
		background-color: #2b2b2b;

		&-item {
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0;
			}

			&-date {
				color: #797979;
			}
			&-changes {
				padding-left: 0;
				list-style-position: inside;
			}
		}
	}
}

.multiauction-name {
	span {
		&:after {
			content: "/";
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}

.VueTables__table {
	margin-bottom: 2rem;
}

.route-and-flight-auction-details {
	position: relative;
	margin-top: 24px;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 1px;
		height: 100%;
		background-color: #c8ced3;
		content: "";

		@media screen and (max-width: 1199px) {
			display: none;
		}
	}

	&__dates {
		.form-group {
			width: 100%;
		}

		@media screen and (max-width: 1199px) {
			position: relative;
			margin-top: 40px;
			padding-top: 20px;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				border: 1px solid #c8ced3;
				content: "";
			}
		}
	}
	&__title {
		margin-top: 0;
		margin-bottom: 8px;
	}
	&__route-container {
		position: relative;
		padding-top: 61px;
		padding-bottom: 37px;
		height: 100%;

		&-title {
			position: absolute;
			top: 0;
		}
		&-desc {
			position: absolute;
			top: 24px;
		}
		&-total {
			position: absolute;
			margin-top: 0.5rem;
		}

		&-second {
			position: absolute;
			margin-top: 2.125rem;
		}
	}
	&__date {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 32px;

		&-title {
			flex: 0 0 100%;
			max-width: 100%;
		}
		&-number {
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: 15px;
		}
		&-time {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 15px;
		}
	}
	&__type {
	}
	&__transport {
		position: relative;
		margin-top: 32px;
		padding-top: 20px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			border: 1px dashed #c8ced3;
			content: "";
		}
	}
	&__form-group {
		&:last-child {
			margin-bottom: 0;
		}

		.col-form-label {
			font-weight: bold;
		}
	}
}

.route-auction {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 480px;
	height: 100%;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 3px;
		height: 100%;
		background-color: #c8ced3;
		content: "";
	}

	&__item {
		position: relative;
		z-index: 1;
		max-width: 480px;
		padding: 10px 6px;
		margin-top: 18px;
		background: #f3f4f5;
		border: 1px solid #c8ced3;

		&:first-child {
			margin-top: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			border: 10px solid transparent;
			border-top: 10px solid #c8ced3;
			content: "";
		}

		&-header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}
		}
		&-number {
			padding-right: 20px;
			white-space: nowrap;
		}
		&-type {
			width: auto;
			min-width: 136px;
			padding: 0 5px;
		}
		&-distance {
			padding-left: 20px;
			white-space: nowrap;
			@media screen and (max-width: 767px) {
				width: 100%;
				padding-left: 0;
			}
		}
		&-address {
			margin-top: 12px;
			margin-bottom: 0;
		}
		&-footer {
			padding-top: 28px;
			display: flex;
			justify-content: space-between;
		}
		&-date {
		}
		&-time {
		}
	}
}

.terms-and-participants {
	&__contractors {
		padding: 1rem;
	}
	&__start-price {
		position: relative;
		height: 100%;
		padding: 1rem;

		&:before {
			position: absolute;
			top: 0;
			left: -0.5px;
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
			@media screen and (max-width: 991px) {
				left: 0;
				width: 100%;
				height: 1px;
			}
		}
	}
	&__auction-duration {
		position: relative;
		margin-top: 32px;
		padding-top: 20px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			border: 1px dashed #c8ced3;
			content: "";
		}
	}
}

.tab-content {
	.tab-pane.terms-and-participants {
		padding: 0;
	}
}

</style>
