<template>
  <div v-if="value && routeWay && routeWay.id">
    <b-form-group
      v-if="dimension"
      label="Основной габарит ТС"
    >
      <el-select-clearable
        v-model="dimensions.value"
        class="form-control"
        name="dimensions"
        placeholder="Выберите габарит"
        clearable
        :filterable="true"
        :remote="true"
        :remote-method="getDimensionsList"
        @change="onMainDimensionChoose(dimensions.value)"
        @clear="getDimensionsList('')"
      >
        <el-option
          v-for="item in dimensions.options"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        />
      </el-select-clearable>
    </b-form-group>
    <b-form-group
      label="Дополнительные габариты ТС"
    >
      <el-select
        v-model="optionalDimensions.value"
        class="form-control"
        name="route-way"
        placeholder="Добавить дополнительный габарит ТС"
        :filterable="true"
        :remote="true"
        reserve-keyword
        :remote-method="getDimensionsList"
        @focus="getDimensionsList('')"
        @change="onOptionalDimensionChoose(optionalDimensions.value)"
      >
        <el-option
          v-for="item in optionalDimensions.options"
          :key="item.value"
          :label="item.text"
          :value="item.value"
          :disabled="item.disabled"
        />
      </el-select>
      <b-form-checkbox-group
        id="checkbox-group-2"
        v-model="selectedOptionalDimensionsIds"
        class="mt-2"
        stacked
        @change="emitValue()"
      >
        <b-form-checkbox
          v-for="(optionalDimension, index) in optionalDimensionsList"
          :key="optionalDimension.dimension.id"
          :value="optionalDimension.dimension.id"
        >
          {{ optionalDimension.dimension.name }}
          <span v-if="optionalDimension.calculated">{{ numberRUFormat(Math.round(optionalDimension.dimension.percentage/100*price)) }} руб.</span>
          <a
            v-b-tooltip.hover.bottom="'Удалить'"
            class="fa fa-close fa-lg text-danger table-action-button"
            @click.prevent="deleteChosenOptionalDimension(optionalDimension.dimension.id)"
          />
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
  </div>
  <div v-else>
    <p class="mb-1"><b>Габариты</b></p>
    <p>Данные по габаритам можно будет изменять, только после сохранения аукциона, при повторном редактировании аукциона</p>
  </div>
</template>

<script>
import ElSelectClearable from '../components/ElSelectClearable.vue';
import {numberToRUFormat} from '@/components/mixins/helpers';
import {routeWayDimensionForRouteWay, transportVehiclesDimensionsTypes, transportVehiclesDimensionsTypesRead} from "../services/api";
import Vue from "vue";

export default {
  name: 'DimensionsEdit',
  components: {
    ElSelectClearable,
  },
  props: ['value', 'routeWay', 'price', 'index'],
  data() {
    return {
      dimension: null,
      dimensions: {
        options: [],
        value: null,
      },
      optionalDimensions: {
        options: [],
        value: null,
      },
      optionalDimensionsList: [],
      selectedOptionalDimensionsIds: [],
    };
  },
  computed: {
    selectedDimension() {
      return {...this.value, ...{dimension: this.dimension,
        optionalDimensions: this.optionalDimensionsList.map((dimension) => {
          return {dimension: dimension.dimension, id: dimension.id, active: this.selectedOptionalDimensionsIds.includes(dimension.dimension.id)};
        }),
      }};
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          if (this.value.dimension) {
            this.dimension = this.value.dimension;
            this.dimensions.value = this.value.dimension.id;
          }
          if (this.value.optionalDimensions && this.value.optionalDimensions.length > 0) {
            this.selectedOptionalDimensionsIds = [];
            this.optionalDimensionsList = [];
            this.value.optionalDimensions.forEach((dimension) => {
              dimension.calculated = true;
              this.optionalDimensionsList.push(dimension);
              if (dimension.active) {
                return this.selectedOptionalDimensionsIds.push(dimension.dimension.id);
              }
            });
          }
          if (this.routeWay.id && (!this.value.dimension) || (!this.value.optionalDimensions)) {
            this.selectedOptionalDimensionsIds = [];
            this.getRouteWayDimension(true);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getDimensionsList('');
    this.emitValue();
  },
  methods: {
    emitValue() {
      this.$emit('input', {...JSON.parse(JSON.stringify(this.selectedDimension))}, this.index);
    },
    async getRouteWayDimension(idChanged = false) {
      const response = await routeWayDimensionForRouteWay(this.$store.state.user.ownerId, this.routeWay.id);
      if (response && response.status === 200) {
        this.dimension = response.data.dimension;
        this.dimensions.value = response.data.dimension.id;
        this.optionalDimensionsList = response.data.optionalDimensions.map((item) => {
          return {dimension: item};
        });
        if (this.value?.optionalDimensions?.length == 0 || idChanged) {
          this.selectedOptionalDimensionsIds = response.data.optionalDimensions.map((dimension) => {
            return dimension.id;
          });
          this.$emit('input', {...JSON.parse(JSON.stringify(this.selectedDimension))}, this.index);
        }
      }
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
    async getDimensionsList(name) {
      const params = {name: name, limit: 99};
      const response = await transportVehiclesDimensionsTypes(params);
      if (response && response.status === 200) {
        this.optionalDimensionsList.forEach((optionalDimension) => {
          response.data.items = response.data.items.filter((item) => item.id !== optionalDimension.dimension.id);
        })
        this.dimensions.options = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
        response.data.items = response.data.items.filter((item) => item.id !== this.dimensions.value);
        this.optionalDimensions.options = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
      }
    },
    async onMainDimensionChoose(dimensionId) {
      const response = await transportVehiclesDimensionsTypesRead(dimensionId);
      if (response && response.status === 200) {
        this.dimension = response.data;
        this.emitValue();
      }
      this.getDimensionsList('');
    },
    async onOptionalDimensionChoose(optionalDimensionId) {
      const response = await transportVehiclesDimensionsTypesRead(optionalDimensionId);
      if (response && response.status === 200) {
        this.optionalDimensionsList.unshift({
          calculated: false,
          dimension: response.data,
        });
        this.selectedOptionalDimensionsIds.push(response.data.id);
        this.emitValue();
        this.optionalDimensions.value = null;
      }
      this.getDimensionsList('');
    },
    deleteChosenOptionalDimension(optionalDimensionId) {
      this.optionalDimensionsList = this.optionalDimensionsList.filter((item) => item.dimension.id !== optionalDimensionId);
      this.emitValue();
      this.getDimensionsList('');
    },
  },
};
</script>
